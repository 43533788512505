<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        Site Scraper
      </div>
      <!--begin::Card title-->
    </div>
    <div class="card-body pt-0">
      <div class="row col-12 mt-2">
        <label for="list-name" class="mb-2">
          List Name
        </label>
        <input class="form-control form-control-solid"
               id="list-name"
               placeholder="Enter a list name"
               v-model="list_name"
        >
      </div>
      <div class="row col-lg-12 mt-3">
        <div class="col-lg-6">
          <Field
            type="radio"
            class="btn-check"
            name="accountType"
            value="paste"
            v-model="uploadType"
            id="kt_create_account_form_account_type_personal"
          />
          <label
            class="
              btn btn-outline btn-outline-dashed btn-outline-default
              p-7
              d-flex
              align-items-center
              mb-10
            "
            for="kt_create_account_form_account_type_personal"
          >
            <span class="svg-icon svg-icon-3x me-5">
              <inline-svg src="media/icons/duotune/communication/com005.svg" />
            </span>

            <!--begin::Info-->
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">
                Paste domain names
              </span>
              <span class="text-gray-400 fw-bold fs-6">
                You can paste upto 5000 domains name
              </span>
            </span>
          </label>
        </div>
        <div class="col-lg-6">
          <Field
            type="radio"
            class="btn-check"
            name="accountType"
            value="upload"
            v-model="uploadType"
            id="kt_create_account_form_account_type_corporate"
          />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                 for="kt_create_account_form_account_type_corporate">
            <span class="svg-icon svg-icon-3x me-5">
              <inline-svg src="media/icons/duotune/finance/fin006.svg" />
            </span>

            <!--begin::Info-->
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">
                Upload CSV
              </span>
              <span class="text-gray-400 fw-bold fs-6">
                Upload a CSV with maximum 25000 domains
              </span>
            </span>
          </label>
        </div>
      </div>

      <div class="row col-12 mt-5" v-if="uploadType === 'paste'">
        <label for="domains" class="mb-2">
          Domain Names
        </label>
        <textarea class="form-control form-control-solid" rows="6"
                  id="domains"
                  v-model="domains"
                  placeholder="google.com
facebook.com"></textarea>
      </div>

      <div class="row col-12 mt-5" v-if="uploadType === 'upload'">
        <input type="file" ref="file" style="display: none" @change="onChangeFileUpload">
        <label
          @click="$refs.file.click()"
          class="
              btn btn-outline btn-outline-dashed btn-outline-default
              p-7
              d-flex
              align-items-center
              mb-2
            ">
            <span class="svg-icon svg-icon-3x me-5">
              <inline-svg src="media/icons/duotune/communication/com005.svg" />
            </span>

          <!--begin::Info-->
          <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">
                {{ this.file ? this.file.name : "Upload a File" }}
              </span>
              <span class="text-gray-400 fw-bold fs-6"
              >{{ this.file ? Math.ceil((this.file.size / 1024)) + " KB" : "Should be in CSV format" }}</span
              >
            </span>
          <!--end::Info-->
        </label>
        <a :href="`${downloadUrl}/sample.csv`">Click here to download a sample CSV file</a>
      </div>

      <div class="row mt-5">
        <div class="col-2">
          <button
            type="button"
            class="btn btn-primary me-10"
            id="kt_button_1"
            :data-kt-indicator="isLoading"
          >
            <span class="indicator-label" @click="save">Save</span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "SiteScraper",
  data() {
    return {
      file: null,
      list_name: null,
      domains: null,
      isLoading: "off",
      uploadType: "paste"
    };
  },
  computed: {
    downloadUrl() {
      return process.env.VUE_APP_DOWNLOAD_URL
    }
  },
  components: {
    Field
  },
  mounted() {
    store.dispatch("setBreadcrumbAction", { title: "Site Scraper" });
  },
  methods: {
    onChangeFileUpload(event) {
      this.file = event.target.files[0];
      console.log(event.target.files[0]);
      console.log(this.file);
    },
    save() {
      if(this.list_name === null || this.list_name === "") {
        Swal.fire({
          text: "Please enter a list name",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary"
          }
        });
        return;
      }
      this.isLoading = "on";
      let formData = new FormData();
      formData.append("list_name", this.list_name);
      formData.append("upload_type", this.uploadType);
      if (this.uploadType === "upload") {
        formData.append("domains_file", this.file);
      }
      if (this.uploadType === "paste") {
        formData.append("domains", this.domains);
      }

      store.dispatch("SiteScraper/save", formData).then((response) => {
        this.isLoading = "off";
        this.file = null;
        this.list_name = null;
        this.domains = null;
        Swal.fire({
          text: response.message,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary"
          }
        });
      });
    }
  }
};
</script>

<style scoped>

</style>